





import Vue from 'vue'
export default Vue.component('QRemainMenuOption', {
  props: {
    menuOption: {
      required: true,
    },
  },
  computed: {
    remain: function() {
      return this.menuOption.menu.remain_order > 0 ? this.menuOption.menu.remain_order : 0
    }
  },
})
